const appConfig = {
    apiPrefix: 'https:///aws.gestaodevalidades.com.br/validades',
    // apiPrefix: 'http:///localhost:3000/validades',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig

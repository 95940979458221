export const APP_NAME = 'Gestão de Validades'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'


export const REL_FILTRO_PRODUTOS = [
    {
        label: 'Produtos',
        value: 'produtos',
    },
    {
        label: 'Departamentos',
        value: 'departamentos',
    }
]